import {
  wrapPageElement as wrapPage,
  wrapRootElement as wrapRoot
} from './wrappers';

import '@fontsource/source-sans-pro/300.css';
import '@fontsource/source-sans-pro/400.css';
import '@fontsource/source-sans-pro/600.css';

export const wrapRootElement = wrapRoot;
export const wrapPageElement = wrapPage;
