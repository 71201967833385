import React from 'react';
import { ThemeProvider } from 'styled-components';

import { Page } from './src/components/global/Page';
import { GlobalStyle, theme } from './src/theme/global-style';

export const wrapRootElement = ({ element, props }) => (
  <ThemeProvider theme={theme}>
    <Page {...props}>{element}</Page>
  </ThemeProvider>
);

export const wrapPageElement = ({ element }) => (
  <>
    <GlobalStyle />
    {element}
  </>
);
