import { rgba } from 'polished';
import {
  createGlobalStyle,
  css,
  FlattenSimpleInterpolation
} from 'styled-components';
import reset from 'styled-reset';

const COLORS = {
  GRAY1: '#091d2b',
  GRAY2: '#6c899e',
  GRAY3: '#b7c6d1',
  GRAY4: '#e4ebf0',
  GRAY5: '#fbfcfd',
  BLACK: '#000809',
  WHITE: '#FFFFFF'
};

interface ThemeType {
  animation: {
    DURATION: string;
    EASING: string;
  };
  borderColor: string;
  breaks: {
    MOBILE: string;
    RESPONSIVE: string;
  };
  colors: typeof COLORS;
  discRoulette: {
    activeColor: string;
    border: FlattenSimpleInterpolation;
    maxWidth: string;
  };
  font: string;
  textColor: { DEFAULT: string; MUTED: string };
  textSize: {
    DEFAULT: string;
    SMALL: string;
  };
}

export const theme = {
  animation: {
    DURATION: '0.25s',
    EASING: 'cubic-bezier(0.25, 0.1, 0.25, 1)'
  },
  font: '"Source Sans Pro", sans-serif',
  colors: COLORS,
  discRoulette: {
    activeColor: '#00ced1',
    border: css`
      ${({ theme }) => rgba(theme.colors.WHITE, 0.2)}
    `,
    maxWidth: '500px'
  },
  textColor: { DEFAULT: COLORS.GRAY1, MUTED: COLORS.GRAY2 },
  borderColor: COLORS.GRAY4,
  textSize: {
    DEFAULT: '18px',
    SMALL: '14px'
  },
  breaks: {
    RESPONSIVE: '1100px',
    MOBILE: '700px'
  }
};

export const GlobalStyle = createGlobalStyle<{ theme: ThemeType }>`
  ${reset}

  

  *, *:before, *:after {
    box-sizing: border-box;
  }

  html {
    box-sizing: border-box;
    scroll-behavior: smooth;
    font-family: ${({ theme }) => theme.font};
    font-weight: 300;
    font-size: ${({ theme }) => theme.textSize.DEFAULT};
  }

  body {
    margin: 0;
    line-height: 27px;
    min-height: 100vh;
    min-width: 100%;
    color: ${({ theme }) => theme.textColor.DEFAULT};
    background: linear-gradient(
      -180deg,
      ${({ theme }) => rgba(theme.colors.GRAY5, 0.5)} 0%,
      ${({ theme }) => rgba(theme.colors.GRAY4, 0.7)} 100%
    );

    ${
      process.env.SUBSITE === 'discroulette' &&
      css`
        overflow: hidden;
      `
    }
  }

  h1 {
    font-size: 40px;
    font-weight: 600;
    letter-spacing: -2px;
    line-height: initial;
  }

  h2, h3, h4 {
    line-height: initial;
    font-size: 22px;
    font-weight: 600;
    letter-spacing: -0.8px;
  }


  ul {
    list-style: none;
  }

  strong {
    font-weight: 400;
  }
`;
