import React, { ReactNode } from 'react';

interface PageProps {
  children: ReactNode;
}

export const Page: React.ComponentType<PageProps> = React.memo(
  ({ children }) => {
    return <>{children}</>;
  }
);
